import { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { student_interface } from "../../interface/interface";

interface StudentCardProps {
  student: student_interface;
}

export default function StudentCard({ student }: StudentCardProps) {
  const [name, setName] = useState(student.name);
  const [introduction, setIntroduction] = useState(student.introduction);
  const [studentId, setStudentId] = useState(student.student_id);
  const [contactNumber, setContactNumber] = useState(student.contact_number);
  const [class_name, setClassName] = useState(student.class_name);
  const [major, setMajor] = useState(student.major);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleStudentIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStudentId(event.target.value);
  };

  const handleContactNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContactNumber(event.target.value);
  };

  const handleClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClassName(event.target.value);
  };

  const handleMajorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMajor(event.target.value);
  };

  const handleUpdate = () => {
    // 处理更新逻辑，发送请求到后端等
    // ...
  };

  const handleDelete = () => {
    // 处理删除逻辑，发送请求到后端等
    // ...
  };

  return (
    <Box>
      <Paper elevation={10}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Stack>
              <Typography fontSize="20px" paddingTop="15px" paddingX="15px">
                {name}
              </Typography>
              <Typography fontSize="15px" paddingBottom="10px" paddingX="15px">
                {studentId}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ marginY: "25px", marginX: "10px" }}
            aria-describedby={id}
            onClick={handleClick}>
            管理
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}>
            <Box sx={{ margin: "10px" }}>
              <Stack spacing={"10px"}>
                <TextField
                  id="name"
                  size="small"
                  label="姓名"
                  variant="outlined"
                  sx={{ width: "100px" }}
                  value={name}
                  onChange={handleNameChange}
                />
                <TextField
                  id="student-id"
                  size="small"
                  label="专业"
                  variant="outlined"
                  sx={{ width: "250px" }}
                  value={major}
                  onChange={handleMajorChange}
                />
                <TextField
                  id="student-id"
                  size="small"
                  label="班级"
                  variant="outlined"
                  sx={{ width: "250px" }}
                  value={class_name}
                  onChange={handleClassChange}
                />

                <TextField
                  id="student-id"
                  size="small"
                  label="学号"
                  variant="outlined"
                  sx={{ width: "250px" }}
                  value={studentId}
                  onChange={handleStudentIdChange}
                />
                <TextField
                  id="contact-number"
                  size="small"
                  label="联系方式"
                  variant="outlined"
                  sx={{ width: "250px" }}
                  value={contactNumber}
                  disabled={true}
                  onChange={handleContactNumberChange}
                />
                <TextField
                  sx={{ minWidth: "80%" }}
                  id="outlined-multi-line-statics"
                  value={introduction}
                  label="个人简介"
                  multiline
                  rows={2}
                  disabled={true}
                />
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleUpdate}>
                  更改
                </Button>
              </Stack>
            </Box>
          </Popover>
        </Stack>
      </Paper>
    </Box>
  );
}
