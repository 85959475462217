import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import JMStudentCard from "./JMStudentCard";
import { Person } from "@mui/icons-material";
interface CompJobCardProps {
  jobTitle: string;
  jobContent: string;
  salary: number;
  feedback1: string;
  job_id: string;
}
interface StudentInterface {
  name: string;
  contact_number: string;
  introduction: string;
  major: string;
  class_name: string;
  student_id: string;
}

const urlParams = new URLSearchParams(window.location.search);
const studentId = urlParams.get("id");
export default function JMJobCards(props: CompJobCardProps) {
  const [students, setStudents] = useState<StudentInterface[]>([]);
  const { job_id, jobTitle, jobContent, salary, feedback1 } = props;
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(
          `https://chickenyards.com/api/campus/real_get_students_for_job/E002/${job_id}`
        ); // Replace "your-api-url" with the actual URL to fetch the JSON data
        const data = await response.json();
        setStudents(data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);
  return (
    <Box paddingY={"10px"}>
      <Paper elevation={10}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Stack>
              <Typography fontSize="20px" paddingTop="15px" paddingX="15px">
                {jobTitle}
              </Typography>
              <Typography fontSize="15px" paddingBottom="10px" paddingX="15px">
                {jobContent}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            size="small"
            color="warning"
            sx={{ marginY: "25px", marginX: "10px" }}>
            审核上线
          </Button>
        </Stack>
        <Stack direction={"row"} paddingX={"40px"}>
          <Person color="primary"></Person>
          <Typography fontSize={"19px"}>已投递学生</Typography>
        </Stack>

        <Container>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                flex: "1 0 auto",
                minWidth: 0,
                marginBottom: "10px",
                padding: "3px",
              },
            }}>
            {students.map((student) => (
              <JMStudentCard student={student} />
            ))}
          </Box>
        </Container>
        <Stack direction={"row"}>
          <Box sx={{ padding: "16px" }}>
            <Typography>{`反馈:${
              feedback1 ? feedback1 : "暂未填写"
            }`}</Typography>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}
