import React from "react";
import { Box } from "@mui/material";
import Signin from "./page_components/SignIn";
import Banner from "../component/Banner";
import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { orange, blue, grey } from "@mui/material/colors";
import GBanner from "./page_components/GBanner";
import AssistsView from "./page_components/Assists/AssitsView";
const theme = createTheme({
  palette: {
    primary: {
      main: blue[100],
    },
    secondary: {
      main: grey[200],
    },
  },
});
const GAssists = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 99,
          }}>
          <GBanner />
        </div>
        <Box
          sx={{
            backgroundImage: `url(https://logincdn.msauth.net/shared/1.0/content/images/appbackgrounds/49_6ffe0a92d779c878835b40171ffc2e13.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            height: "300vh",
          }}>
          <Box
            sx={{
              display: "flex",
              marginTop: "15px",
              height: "100vh",
            }}>
            <AssistsView />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default GAssists;
