import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import StudentCard from "./StudentCard";
import { student_interface } from "../../interface/interface";

export default function StudentFlow() {
  const [students, setStudents] = useState<student_interface[]>([]);

  useEffect(() => {
    // 发起 GET 请求获取学生数据
    fetch("https://chickenyards.com/api/campus/stu_admin_get_all_students/")
      .then((response) => response.json())
      .then((data) => {
        setStudents(data);
      })
      .catch((error) => {
        console.log("请求学生数据失败", error);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          flex: "1 0 auto",
          minWidth: 0,
          minHeight: 30,
          maxWidth: "50%",
          marginBottom: "3px",
          padding: "3px",
        },
        paddingTop: "8px",
      }}>
      {students.map((student) => (
        <StudentCard key={student.student_id} student={student} />
      ))}
    </Box>
  );
}
