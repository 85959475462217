import { Box, Button, Paper, Stack, Typography, Chip } from "@mui/material";
import { job_interface } from "../../interface/interface";

interface StuJobCardProps {
  job: job_interface;
  is_apply: boolean;
}
const urlParams = new URLSearchParams(window.location.search);
const student_id = urlParams.get("id");
const StuJobCard: React.FC<StuJobCardProps> = ({ job, is_apply }) => {
  const handleButtonClick = () => {
    if (true) {
      // 发起 GET 请求到特定 URL
      fetch(
        `https://chickenyards.com/api/campus/student_apply_for_job/${job.job_number}/${student_id}`
      )
        .then((response) => {
          if (response.ok) {
            // 在这里处理响应成功的情况
            console.log("GET 请求成功");
            window.location.reload();
          } else {
            // 在这里处理响应错误的情况
            console.log("GET 请求失败");
          }
        })
        .catch((error) => {
          // 在这里处理请求过程中的错误
          console.log("发生错误", error);
        });
    }
  };

  return (
    <Box>
      <Paper elevation={10}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Stack>
              <Stack direction="row">
                <Typography fontSize="20px" paddingTop="15px" paddingX="15px">
                  {job.job_title}
                </Typography>
                <Chip
                  size="small"
                  sx={{ alignSelf: "end" }}
                  label={`每月${job.salary}￥`}
                />
              </Stack>

              <Typography fontSize="15px" paddingBottom="10px" paddingX="15px">
                {job.job_content}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            size="small"
            color={is_apply ? "success" : "error"}
            sx={{ marginY: "25px", marginX: "10px" }}
            onClick={handleButtonClick} // 添加按钮点击事件处理程序
          >
            {is_apply ? "已投递" : "投递"}
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default StuJobCard;
