import { useState } from "react";
import {
  Typography,
  Container,
  Paper,
  Stack,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import StudentFlow from "./StudentFlow";
import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { brief_interface, student_affair } from "../../interface/interface";
import { useEffect } from "react";
interface StudentData {
  name: string;
  major: string;
  student_id: string;
  contact_number: string;
  class_name: string;
}
const urlParams = new URLSearchParams(window.location.search);
const student_id = urlParams.get("id");
const StudentAdminView = () => {
  const [name, setName] = React.useState("");
  const [major, setMajor] = React.useState("");
  const [studentId, setStudentId] = React.useState("");
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleMajorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMajor(event.target.value);
  };

  const handleStudentIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStudentId(event.target.value);
  };
  const handleAddStudent = () => {
    const studentData: StudentData = {
      name: name,
      major: major,
      student_id: studentId,
      contact_number: "",
      class_name: "",
    };

    fetch(
      `https://chickenyards.com/api/campus/stu_admin_create_student/${student_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(studentData),
      }
    ).then(() => {
      fetch(
        `https://chickenyards.com/api/campus/get_student_affair_by_id/${student_id}`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      fetch(`https://chickenyards.com/api/campus/assists_admin_get_brief/`)
        .then((response) => response.json())
        .then((responseData) => {
          setbrief(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      fetch(
        `https://chickenyards.com/api/campus/get_student_affair_by_id/${student_id}`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });
  };
  const [studentData, setStudentData] = useState<student_affair>({
    stu_admin_id: 0,
    stu_admin_name: "",
  });
  const [brief, setbrief] = useState<brief_interface>({
    total_populations: "",
    applied_populations: "",
    max_wage: "",
    min_wage: "",
    no_jobs_populations: 1,
    one_or_two_jobs_populations: 1,
    three_or_more_jobs_populations: 1,
  });
  useEffect(() => {
    const fetchData = () => {
      fetch(
        `https://chickenyards.com/api/campus/get_student_affair_by_id/${student_id}`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      fetch(`https://chickenyards.com/api/campus/assists_admin_get_brief/`)
        .then((response) => response.json())
        .then((responseData) => {
          setbrief(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, []);

  // Check if the username field is empty
  const [age, setAge] = React.useState("0");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <Container maxWidth="md">
      <Box>
        <Paper elevation={8}>
          <Box
            sx={{
              height: "100%", // 设置容器高度为100%
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px",
            }}>
            <Stack direction="row" spacing={3} alignSelf="flex-start">
              <Stack>
                <Typography fontSize={"30px"} alignSelf="auto">
                  {studentData.stu_admin_name}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"教工号"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.stu_admin_id}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <Box paddingY={"10px"}>
        <Paper elevation={8}>
          <Box padding={"10px"}>
            <Stack direction={"row"}>
              <TextField
                id="outlined-basic"
                size="small"
                label="姓名"
                variant="outlined"
                sx={{ width: "100px" }}
                onChange={handleNameChange}
              />

              <TextField
                id="outlined-basic"
                size="small"
                label="专业"
                variant="outlined"
                sx={{ width: "100px" }}
                onChange={handleMajorChange}
              />
              <TextField
                id="outlined-basic"
                size="small"
                label="学号"
                variant="outlined"
                sx={{ width: "250px" }}
                onChange={handleStudentIdChange}
              />
              <Button
                variant="contained"
                color="error"
                onClick={handleAddStudent}>
                {" "}
                新增学生
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <Box>
        <Stack direction={"row"}>
          <PieChart
            series={[
              {
                data: [
                  {
                    color: "#FFF5C2",
                    id: 0,
                    value: brief.no_jobs_populations,
                    label: "0份工作",
                  },
                  {
                    color: "#F4F27E",
                    id: 1,
                    value: brief.no_jobs_populations,
                    label: "1-2份工作",
                  },
                  {
                    color: "#3081D0",
                    id: 2,
                    value: brief.three_or_more_jobs_populations,
                    label: ">3份工作",
                  },
                ],
              },
            ]}
            width={400}
            height={200}
          />
          <Paper elevation={10} sx={{ minWidth: "50%" }}>
            <Typography fontSize={"18px"} padding={"15px"}>
              {brief.total_populations}
              <br />
              {brief.applied_populations}
              <br />
              {brief.max_wage}
              <br />
              {brief.min_wage}
            </Typography>
          </Paper>
        </Stack>
      </Box>
      <StudentFlow />
    </Container>
  );
};

export default StudentAdminView;
