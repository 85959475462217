import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Paper,
  Stack,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import StuJobFlow from "./StuJobFlow";

export interface StudentInterface {
  name: string;
  contactNumber: string;
  introduction: string;
  major: string;
  className: string;
  studentId: string;
}

const urlParams = new URLSearchParams(window.location.search);
const studentId = urlParams.get("id");

const StudentView = () => {
  const [studentData, setStudentData] = useState<StudentInterface>({
    name: "",
    contactNumber: "",
    introduction: "",
    major: "",
    className: "",
    studentId: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://chickenyards.com/api/campus/get_student_by_id/${studentId}`
        );
        const responseData = await response.json();
        setStudentData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://chickenyards.com/api/campus/student_update_infos/${studentId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(studentData),
        }
      );
      const responseData = await response.json();
      console.log("Update response:", responseData);
    } catch (error) {
      console.error("Error updating student:", error);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Container maxWidth="md">
      <Box>
        <Paper elevation={8}>
          <Box
            sx={{
              height: "100%",
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px",
            }}>
            <Stack direction="row" spacing={3} alignSelf="flex-start">
              <Stack>
                <Typography fontSize={"30px"} alignSelf="auto">
                  {studentData.name}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"专业"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.major}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"班级"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.className}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"学号"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.studentId}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"性别"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {"未知"}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={"row"} sx={{ margin: "10px" }}>
                  <TextField
                    sx={{ minWidth: "80%" }}
                    id="outlined-multiline-static"
                    value={studentData.introduction}
                    label="个人简介"
                    multiline
                    rows={2}
                    name="introduction"
                    onChange={handleChange}
                  />
                  <Button variant="outlined" onClick={handleUpdate}>
                    更新个人信息
                  </Button>
                </Stack>
                <Box sx={{ marginX: "10px" }}>
                  <TextField
                    sx={{ minWidth: "80%" }}
                    size="small"
                    id="outlined-multiline-static"
                    label="联系方式"
                    value={studentData.contactNumber}
                    name="contactNumber"
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ margin: "10px" }}>
                  <TextField
                    sx={{ minWidth: "80%" }}
                    size="small"
                    id="outlined-multiline-static"
                    label="空闲时间"
                    value={"未知"}
                  />
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <StuJobFlow />
    </Container>
  );
};

export default StudentView;
