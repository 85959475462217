import Box from "@mui/material/Box";
import { Button, Paper, Stack, Typography, TextField } from "@mui/material";
import * as React from "react";
import Popover from "@mui/material/Popover";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { student_interface } from "../../interface/interface";

interface StaticStudentCardProps {
  student: student_interface;
}

export default function StaticStudentCard({ student }: StaticStudentCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleChange = (event: SelectChangeEvent) => {
    // Handle change logic here
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Paper elevation={10}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Stack>
              <Typography fontSize="20px" paddingTop="15px" paddingX="15px">
                {student.name}
              </Typography>
              <Typography fontSize="15px" paddingBottom="10px" paddingX="15px">
                {student.student_id}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ marginY: "25px", marginX: "10px" }}
            aria-describedby={id}
            onClick={handleClick}>
            详情
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}>
            <Box sx={{ margin: "10px" }}>
              <Stack spacing="10px">
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="姓名"
                  variant="outlined"
                  value={student.name}
                  sx={{ width: "100px" }}
                  disabled
                />

                <TextField
                  disabled
                  id="outlined-basic"
                  size="small"
                  label="学号"
                  variant="outlined"
                  value={student.student_id}
                  sx={{ width: "250px" }}
                />
                <TextField
                  disabled
                  id="outlined-multiline-static"
                  label="个人简介"
                  multiline
                  rows={3}
                  value={student.introduction}
                />
              </Stack>
            </Box>
          </Popover>
        </Stack>
      </Paper>
    </Box>
  );
}
