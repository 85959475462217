import { useState } from "react";
import {
  Typography,
  Container,
  Paper,
  Stack,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import JMJobCardsFlow from "./JMJobCardsFlow";
import * as React from "react";
import { useEffect } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { jobmanager_interface } from "../../interface/interface";

const urlParams = new URLSearchParams(window.location.search);
const student_id = urlParams.get("id");
const JManagerView = () => {
  // Check if the username field is empty
  const [age, setAge] = React.useState("0");
  const [studentData, setStudentData] = useState<jobmanager_interface>({
    work_admin_id: 0,
    work_admin_name: "",
  });
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  useEffect(() => {
    const fetchData = () => {
      fetch(
        `https://chickenyards.com/api/campus/get_work_study_admin_by_id/${student_id}`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, []);
  return (
    <Container maxWidth="md">
      <Box>
        <Paper elevation={8}>
          <Box
            sx={{
              height: "100%", // 设置容器高度为100%
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px",
            }}>
            <Stack direction="row" spacing={3} alignSelf="flex-start">
              <Stack>
                <Typography fontSize={"30px"} alignSelf="auto">
                  {studentData.work_admin_name}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"工号"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.work_admin_id}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>

      <JMJobCardsFlow />
    </Container>
  );
};

export default JManagerView;
