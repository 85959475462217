import Box from "@mui/material/Box";
import StaticStudentCard from "./StaticStudentCard";
import { useState, useEffect } from "react";
import { student_interface } from "../../interface/interface";

export default function StaticStudentFlow() {
  const [students, setStudents] = useState<student_interface[]>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const student_id = urlParams.get("id");

  useEffect(() => {
    // 发起 GET 请求获取学生数据
    fetch(
      `https://chickenyards.com/api/campus/get_counselor_by_id/${student_id}`
    )
      .then((response1) => response1.json())
      .then((response1) => {
        fetch(
          `https://chickenyards.com/api/campus/assists_get_students/${response1.major}`
        )
          .then((response) => response.json())
          .then((data) => {
            setStudents(data);
          });
      })
      .catch((error) => {
        console.log("请求学生数据失败", error);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          flex: "1 0 auto",
          minWidth: 0,
          minHeight: 30,
          maxWidth: "50%",
          marginBottom: "3px",
          padding: "3px",
        },
        paddingTop: "8px",
      }}>
      {students.map((student) => (
        <StaticStudentCard key={student.student_id} student={student} />
      ))}
    </Box>
  );
}
