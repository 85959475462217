import Box from "@mui/material/Box";
import {
  Button,
  Paper,
  Stack,
  Typography,
  TextField,
  colors,
} from "@mui/material";
import * as React from "react";
import Popover from "@mui/material/Popover";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Check } from "@mui/icons-material";

interface StudentInterface {
  name: string;
  contact_number: string;
  introduction: string;
  major: string;
  class_name: string;
  student_id: string;
}

interface CompStudentCardProps {
  student: StudentInterface;
}

const CompStudentCard = (props: CompStudentCardProps) => {
  const { student } = props;
  const [age, setAge] = React.useState("0");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Paper elevation={2}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Stack>
              <Typography fontSize="20px" paddingTop="10px" paddingX="10px">
                {student.name}
              </Typography>
            </Stack>
          </Box>
          <Check
            color="success"
            sx={{ alignSelf: "center", color: "success" }}
          />
          <Button
            variant="contained"
            size="small"
            sx={{ marginY: "10px", marginX: "10px" }}
            aria-describedby={id}
            onClick={handleClick}>
            详情
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}>
            <Box
              sx={{
                margin: "10px",
              }}>
              <Stack spacing={"10px"}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="姓名"
                  variant="outlined"
                  value={student.name}
                  sx={{ width: "100px" }}
                  disabled={true}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="专业"
                  variant="outlined"
                  value={student.major}
                  sx={{ width: "100px" }}
                  disabled={true}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="空闲时间"
                  variant="outlined"
                  value={student.class_name}
                  sx={{ width: "100px" }}
                  disabled={true}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="联系方式"
                  variant="outlined"
                  value={student.contact_number}
                  sx={{ width: "100px" }}
                  disabled={true}
                />

                <TextField
                  disabled={true}
                  id="outlined-basic"
                  size="small"
                  label="学号"
                  variant="outlined"
                  sx={{ width: "250px" }}
                  value={student.student_id}
                />
                <TextField
                  disabled={true}
                  id="outlined-multiline-static"
                  label="个人简介"
                  multiline
                  rows={3}
                  value={student.introduction}
                />
              </Stack>
            </Box>
          </Popover>
        </Stack>
      </Paper>
    </Box>
  );
};

export default CompStudentCard;
