import { useState } from "react";
import {
  Typography,
  Container,
  Paper,
  Stack,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import CompJobFlow from "./CompJobFlow";
import { useEffect } from "react";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { company } from "../../interface/interface";
interface newjob {
  job_number: string;
  job_title: string;
  job_content: string;
  salary: string;
}
const urlParams = new URLSearchParams(window.location.search);
const student_id = urlParams.get("id");
const CompView = () => {
  // Check if the username field is empty
  const [age, setAge] = React.useState("0");
  const [job_number, setjobnumber] = React.useState("");
  const [job_name, setName] = React.useState("");
  const [job_description, setjob_description] = React.useState("");
  const [job_salary, setjob_salary] = React.useState("");
  const handlejob_number = (event: React.ChangeEvent<HTMLInputElement>) => {
    setjobnumber(event.target.value);
  };
  const handlejob_description = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setjob_description(event.target.value);
  };
  const handlejob_salary = (event: React.ChangeEvent<HTMLInputElement>) => {
    setjob_salary(event.target.value);
  };
  const handlejob_name = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const [studentData, setStudentData] = useState<company>({
    employer_name: "",
    employer_id: 0,
    contact_number: "",
  });
  const handleAddJob = () => {
    const studentData: newjob = {
      job_number: job_number,
      job_title: job_name,
      job_content: job_description,
      salary: job_salary,
    };

    fetch(`https://chickenyards.com/api/campus/make_new_job/${student_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(studentData),
    }).then(() => {
      fetch(
        `https://chickenyards.com/api/campus/get_employer_by_id/${student_id}`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });
  };
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  useEffect(() => {
    const fetchData = () => {
      fetch(
        `https://chickenyards.com/api/campus/get_employer_by_id/${student_id}`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, []);
  return (
    <Container maxWidth="md">
      <Box>
        <Paper elevation={8}>
          <Box
            sx={{
              height: "100%", // 设置容器高度为100%
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px",
            }}>
            <Stack direction="row" spacing={3} alignSelf="flex-start">
              <Stack>
                <Typography fontSize={"30px"} alignSelf="auto">
                  {studentData.employer_name}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "120px" }} label={"用人单位号"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.employer_id}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <Box paddingTop={"10px"}>
        <Paper elevation={10}>
          <Stack
            direction={"row"}
            padding={"15px"}
            justifyContent="space-between">
            <Stack>
              <Stack direction={"row"}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="职位名"
                  variant="outlined"
                  onChange={handlejob_name}
                  sx={{ width: "100px" }}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="工号"
                  variant="outlined"
                  onChange={handlejob_number}
                  sx={{ width: "150px" }}
                />
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="薪资待遇"
                  variant="outlined"
                  onChange={handlejob_salary}
                  sx={{ width: "150px" }}
                />
              </Stack>
              <Box sx={{ paddingTop: "10px" }}>
                <TextField
                  id="outlined-multiline-static"
                  label="职位简介"
                  multiline
                  rows={2}
                  onChange={handlejob_description}
                  sx={{ width: "600px" }}
                />
              </Box>
            </Stack>
            <Button variant="contained" color="success" onClick={handleAddJob}>
              {" "}
              新增岗位
            </Button>
          </Stack>
        </Paper>
      </Box>
      <CompJobFlow />
    </Container>
  );
};

export default CompView;
