import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import StuJobCard from "./StuJobCard";
import { job_interface } from "../../interface/interface";
const urlParams = new URLSearchParams(window.location.search);
const student_id = urlParams.get("id");
export default function StuJobFlow() {
  const [jobs, setJobs] = useState<job_interface[]>([]);
  const [rjobs, rsetJobs] = useState<job_interface[]>([]);

  useEffect(() => {
    fetch(
      `https://chickenyards.com/api/campus/student_load_related_jobs/${student_id}`
    )
      .then((response) => response.json())
      .then((data) => setJobs(data))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(
      `https://chickenyards.com/api/campus/student_load_unrelated_jobs/${student_id}`
    )
      .then((response) => response.json())
      .then((data) => rsetJobs(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          flex: "1 0 auto",
          minWidth: 0,
          minHeight: 30,
          maxWidth: "50%",
          marginBottom: "3px",
          padding: "3px",
        },
        paddingTop: "8px",
      }}>
      {jobs.map((job) => (
        <StuJobCard key={job.job_id} job={job} is_apply={true} />
      ))}

      {rjobs.map((rjob) => (
        <StuJobCard key={rjob.job_id} job={rjob} is_apply={false} />
      ))}
    </Box>
  );
}
