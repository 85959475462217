import { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Stack,
} from "@mui/material";
import { AccountCircle, WorkOutline } from "@mui/icons-material";

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    // Create an object with the account and password
    const data = {
      username: username,
      password: password,
    };

    // Send the POST request
    fetch("https://chickenyards.com/api/campus/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        if (responseData.role === "student") {
          window.location.href = `https://chickenyards.com/groupwork/student?id=${responseData.student_id}`;
        } else if (responseData.role === "counselor") {
          window.location.href = `https://chickenyards.com/groupwork/assists?id=${responseData.employee_id}`;
        } else if (responseData.role === "student_affair") {
          window.location.href = `https://chickenyards.com/groupwork/StudentAdmin?id=${responseData.stu_admin_id}`;
        } else if (responseData.role === "employer") {
          window.location.href = `https://chickenyards.com/groupwork/company?id=${responseData.employer_id}`;
        } else if (responseData.role === "work_study_admin") {
          window.location.href = `https://chickenyards.com/groupwork/Manager?id=${responseData.work_admin_id}`;
        } else {
          console.log("WTF?");
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  };

  // Check if the username field is empty
  const isUsernameEmpty = username.trim() === "";

  return (
    <Container maxWidth="xs">
      <Paper elevation={8}>
        <Stack
          sx={{
            height: "100%",
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px",
          }}
          spacing={1}>
          <Stack direction="row" spacing={1} alignSelf="flex-start">
            <WorkOutline color="primary" sx={{ fontSize: 48 }} />
            <Stack>
              <Typography component="h1" variant="h5" alignSelf="center">
                勤工系统 - Sign in
              </Typography>
            </Stack>
          </Stack>

          <Stack
            component="form"
            spacing={2}
            sx={{ mt: 1, width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="用户名"
              name="username"
              type="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="密码"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2, maxWidth: "100px", alignSelf: "end" }}
              disabled={isUsernameEmpty}>
              下一步
            </Button>
          </Stack>
        </Stack>
      </Paper>

      <Paper elevation={10} sx={{ marginTop: "10px", padding: "10px" }}>
        <Stack direction="row" spacing={1} alignSelf="flex-start">
          <AccountCircle sx={{ fontSize: 30 }} />
          <Typography alignSelf="center">没有账号？注册</Typography>
        </Stack>
      </Paper>
    </Container>
  );
};

export default Signin;
