import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Article from "./pages/Article";

import HttpTest from "./pages/GroupworkAPP";
import GStudentPage from "./pages/GStudentPage";
import GStudentAdmin from "./pages/GStudentAdmin";
import GAssists from "./pages/GAssists";
import GComp from "./pages/GComp";
import JManagerView from "./pages/page_components/JobManager/JManagerView";
import GJobManager from "./pages/GJobManager";
const container = document.getElementById("root");
const root = createRoot(container!);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Article",
    element: <Article />,
  },
  {
    path: "/Groupwork",
    element: <HttpTest />,
  },
  {
    path: "/Groupwork/Student",
    element: <GStudentPage />,
  },
  {
    path: "/Groupwork/StudentAdmin",
    element: <GStudentAdmin />,
  },
  {
    path: "/Groupwork/Assists",
    element: <GAssists />,
  },
  {
    path: "/Groupwork/Company",
    element: <GComp />,
  },
  {
    path: "/Groupwork/Manager",
    element: <GJobManager />,
  },
]);

root.render(<RouterProvider router={router} />);
