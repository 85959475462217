import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  TextField,
  Chip,
} from "@mui/material";
import CompStudentCard from "./CompStudentCard";
import { Person } from "@mui/icons-material";
import { useState, useEffect } from "react";
interface CompJobCardProps {
  jobTitle: string;
  jobContent: string;
  salary: number;
  feedback1: string;
  job_id: string;
}
interface StudentInterface {
  name: string;
  contact_number: string;
  introduction: string;
  major: string;
  class_name: string;
  student_id: string;
}
const urlParams = new URLSearchParams(window.location.search);
const studentId = urlParams.get("id");
const CompJobCard = (props: CompJobCardProps) => {
  const { job_id, jobTitle, jobContent, salary, feedback1 } = props;
  const [students, setStudents] = useState<StudentInterface[]>([]);
  const [feedback, setFeedback] = useState("");
  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };
  const handleSubmitFeedback = () => {
    const url = `http://127.0.0.1:8000/api/submit_job_feedback/${studentId}/${job_id}/${feedback}`;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          // Handle successful response
          console.log("Feedback submitted successfully");
        } else {
          // Handle error response
          console.error("Error submitting feedback");
        }
      })
      .catch((error) => {
        // Handle network error
        console.error("Error submitting feedback:", error);
      });
  };
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(
          `https://chickenyards.com/api/campus/get_students_for_job/${studentId}/${job_id}`
        ); // Replace "your-api-url" with the actual URL to fetch the JSON data
        const data = await response.json();
        setStudents(data);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, []);
  return (
    <Box paddingY={"10px"}>
      <Paper elevation={10}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Stack>
              <Stack direction={"row"}>
                <Typography
                  alignSelf={"center"}
                  fontSize="20px"
                  paddingTop="15px"
                  paddingX="15px">
                  {jobTitle}
                </Typography>
                <Chip
                  size="small"
                  sx={{ alignSelf: "end" }}
                  label={`每月${salary}￥`}
                />
              </Stack>
              <Typography fontSize="15px" paddingBottom="10px" paddingX="15px">
                {jobContent}
              </Typography>
            </Stack>
          </Box>
          <Button
            variant="contained"
            size="small"
            color="warning"
            sx={{ marginY: "25px", marginX: "10px" }}>
            管理
          </Button>
        </Stack>
        <Stack direction={"row"} paddingX={"40px"}>
          <Person color="primary" />
          <Typography fontSize={"19px"}>已投递学生</Typography>
        </Stack>

        <Container>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                flex: "1 0 auto",
                minWidth: 0,
                marginBottom: "10px",
                padding: "3px",
              },
            }}>
            {students.map((student) => (
              <CompStudentCard student={student} />
            ))}
          </Box>
        </Container>
        <Stack direction={"row"}>
          <Box sx={{ padding: "16px" }}>
            <Box sx={{ padding: "16px" }}>
              <TextField
                id="outlined-multiline-static"
                label="反馈"
                multiline
                rows={2}
                sx={{ width: "600px" }}
                onChange={handleFeedbackChange}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            size="small"
            color="warning"
            sx={{ marginY: "25px", marginX: "10px" }}
            onClick={handleSubmitFeedback}>
            提交反馈
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default CompJobCard;
