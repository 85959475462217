import { useState } from "react";
import {
  Typography,
  Container,
  Paper,
  Stack,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import StaticStudentFlow from "./StaticStudentFlow";
import { useEffect } from "react";
import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { assists_interface, brief_interface } from "../../interface/interface";
import Select, { SelectChangeEvent } from "@mui/material/Select";
const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get("id");
const AssistsView = () => {
  // Check if the username field is empty
  const [age, setAge] = React.useState("0");
  const [studentData, setStudentData] = useState<assists_interface>({
    employee_id: "",
    major: "",
    name: "",
  });
  const [brief, setbrief] = useState<brief_interface>({
    total_populations: "",
    applied_populations: "",
    max_wage: "",
    min_wage: "",
    no_jobs_populations: 1,
    one_or_two_jobs_populations: 1,
    three_or_more_jobs_populations: 1,
  });
  useEffect(() => {
    const fetchData = () => {
      fetch(`https://chickenyards.com/api/campus/get_counselor_by_id/${id}`)
        .then((response) => response.json())
        .then((responseData) => {
          setStudentData(responseData);
          fetch(
            `https://chickenyards.com/api/campus/assists_get_brief/${responseData.major}`
          )
            .then((response) => response.json())
            .then((responseData) => {
              setbrief(responseData);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
          fetch(
            `https://chickenyards.com/api/campus/assists_get_brief/${responseData.major}`
          )
            .then((response) => response.json())
            .then((responseData) => {
              setbrief(responseData);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      //assists_get_students
    };

    fetchData();
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <Container maxWidth="md">
      <Box>
        <Paper elevation={8}>
          <Box
            sx={{
              height: "100%", // 设置容器高度为100%
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "30px",
            }}>
            <Stack direction="row" spacing={3} alignSelf="flex-start">
              <Stack>
                <Typography fontSize={"30px"} alignSelf="auto">
                  {studentData.name}
                </Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"专业"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.major}
                    </Typography>
                  </Stack>

                  <Stack direction={"row"} padding={"2px"}>
                    <Chip sx={{ maxWidth: "80px" }} label={"教工号"} />
                    <Typography paddingX={"10px"} alignSelf="center">
                      {studentData.employee_id}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>

      <Box sx={{ paddingTop: "15px" }}>
        <Stack direction={"row"}>
          <PieChart
            series={[
              {
                data: [
                  {
                    color: "#FFF5C2",
                    id: 0,
                    value: brief.no_jobs_populations,
                    label: "0份工作",
                  },
                  {
                    color: "#F4F27E",
                    id: 1,
                    value: brief.no_jobs_populations,
                    label: "1-2份工作",
                  },
                  {
                    color: "#3081D0",
                    id: 2,
                    value: brief.three_or_more_jobs_populations,
                    label: ">3份工作",
                  },
                ],
              },
            ]}
            width={400}
            height={200}
          />
          <Paper elevation={10} sx={{ minWidth: "50%" }}>
            <Typography fontSize={"18px"} padding={"15px"}>
              {brief.total_populations}
              <br />
              {brief.applied_populations}
              <br />
              {brief.max_wage}
              <br />
              {brief.min_wage}
            </Typography>
          </Paper>
        </Stack>
      </Box>
      <StaticStudentFlow />
    </Container>
  );
};

export default AssistsView;
