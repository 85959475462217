import { Box, Container } from "@mui/material";
import CompJobCard from "./CompJobCard";
import React, { useState, useEffect } from "react";

export interface JobInterface {
  job_number: string;
  is_approved: boolean;
  job_title: string;
  job_content: string;
  salary: number;
  feedback1: string;
}

const urlParams = new URLSearchParams(window.location.search);
const studentId = urlParams.get("id");

const CompJobFlow = () => {
  const [jobList, setJobList] = useState<JobInterface[]>([]);

  useEffect(() => {
    const fetchJobList = async () => {
      try {
        const response = await fetch(
          `https://chickenyards.com/api/campus/job_manager_load_jobs/${studentId}`
        );
        const data = await response.json();
        setJobList(data);
      } catch (error) {
        console.error("Error fetching job list:", error);
      }
    };

    fetchJobList();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          flex: "1 0 auto",
          minWidth: 0,
          minHeight: 30,
          marginBottom: "3px",
          padding: "3px",
        },
        paddingTop: "8px",
      }}>
      <Container>
        {jobList.map((job) => (
          <CompJobCard
            key={job.job_number}
            jobTitle={job.job_title}
            jobContent={job.job_content}
            salary={job.salary}
            feedback1={job.feedback1}
            job_id={job.job_number}
          />
        ))}
      </Container>
    </Box>
  );
};

export default CompJobFlow;
